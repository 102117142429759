.h-full {
    height: 100% !important;
}

.relative {
    position: relative;
}

.jss1257 {
    height: 510px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.jss1256{
    width: 230px;
}

.jss1254 {
    -index: 91;
    overflow: hidden;
    position: relative;
    // transition: width 250ms ease;
    @media (max-width: 959.95px) {
        top: 0;
        left: 0;
        bottom: 0;
        position: absolute;
    }
}

.jss668 {
    width: 40px;
    height: 40px;
}

.onlineStatus {
    right: -3px;
    width: 14px;
    border: 2px solid white;
    bottom: 0px;
    height: 14px;
    position: absolute;
    border-radius: 7px;
}

.chat-list {
    // min-height: 500px;
    width: 230px;
    z-index: 91;
    overflow: hidden;
    position: relative;
    transition: width 250ms ease;
    @media (max-width: 959.95px) {
        top: 0;
        left: 0;
        bottom: 0;
    }
}

.jss390 {
    height: 560px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.h-600 {
    height: 600px;
}

.items-center {
    align-items: center;
}

.ml-15 {
    margin-left: 15px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-4 {
    margin-left: 1rem !important;
}

.mr-4 {
    margin-right: 0.75rem !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-15 {
    padding: 15px !important;
}

.p-10 {
    padding: 10px !important;
}

.pl-3 {
    padding-left: 0.75rem !important;
}

.pl-4 {
    padding-left: 1.75rem !important;
}

.ps {
    overflow: hidden!important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
  }

  .borderWhite {
    border: 1px solid white;
}

.jss482 {
    color: rgba(52, 49, 76, 1);
    display: inline-block;
}

.rounded {
    overflow: hidden !important;
    border-radius: 300px !important;
}

.justify-center {
    justify-content: center;
}

.whitespace-pre-wrap {
    word-break: break-word;
    white-space: pre-wrap;
}

.border-radius-4 {
    overflow: hidden;
    border-radius: 4px !important;
}


.border-radius-20 {
    overflow: hidden;
    border-radius: 20px !important;
}


.chat-form {
    height: inherit !important;
}

.chat-textbox {
    padding-left: 5px;
    padding-right: 8px;
}

.chat-amount{
    color: red;
}

.chat-bubble {
    max-width: 480px;
    @include media(767px) {
        max-width: 220px;
      }
}

.chat-scroll {
    height: 0;
}

.contact-skeleton {
    margin-left: 10px;
    padding-top: 15px;   
}

.mobile-top-left {
    width: -webkit-fill-available !important;
}

.message-content {
    a {
        color:#ffadb7;
    }
}

.compose {
    position: fixed;
    bottom: 0px;
    width: 100%;
    @media screen and (min-width: 1200px) {
        width: calc(100% - 260px);
    }
}