.circular-image-small {
  height: 36px;
  width: 36px;
  border-radius: 50%;
}


.brand-logo-30 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}


.brand-logo-topbar {
  img {
    height: 40px;
  }
}